export const defaultEditorContent = {
  type: "doc",
  content: [
    {
      type: "heading",
      attrs: { level: 2 },
      content: [{ type: "text", text: "AI协作使用说明" }],
    },
    {
      type: "paragraph",
      content: [
        {
          type: "text",
          marks: [
            {
              type: "link",
              attrs: {
                href: "https://write.gowithai.help",
                target: "_blank",
              },
            },
          ],
          text: "AI协作",
        },
        {
          type: "text",
          text: "，一个帮你修改润色文档的助手",
        },

        
      ],
    },
    
    
    {
      type: "heading",
      attrs: { level: 3 },
      content: [{ type: "text", text: "用法" }],
    },
    {
      type: "codeBlock",
      attrs: { language: null },
      content: [
        {
          type: "text",
          text: '选中任意文字，自动弹出工具栏（部分手机会有自带文本框挡住页面文本框，建议切换为桌面版），\n点击 AI助我 按钮下面选择你需要的功能。\n在行首处，输入英文/符号也能开启工具栏。\n点击顶部写作栏，沉浸式写作。\n注意：手机打开时不好用，建议电脑使用。\n具体功能，敬请挖掘。',
        },
      ],
    },




    {
      type: "heading",
      attrs: { level: 3 },
      content: [{ type: "text", text: "试验场" }],
    },


    {
      type: "orderedList",
      attrs: { tight: true, start: 1 },
      content: [

        {
          type: "listItem",
          content: [
            {
              type: "paragraph",
              content: [
                { type: "text", text: "选中下面这行文字，在浮窗左侧点击 AI助我 ，选择你想让AI帮你做的事（推荐使用扩写功能） " },
              ],
            },
          ],
        },

        {
          type: "listItem",
          content: [
            {
              type: "paragraph",
              content: [{ type: "text", text: "选中右侧文字：窗外的麻雀，在电线杆上斗嘴，你说这一句，很有夏天的感觉" }],
            },
          ],
        },


        {
          type: "listItem",
          content: [
            {
              type: "paragraph",
              content: [
                {
                  type: "text",
                  text: "拖动-放下就可以添加图像了；删除图像时用delete键. ",
                },
              ],
            },
          ],
        },


        
        {
          type: "listItem",
          content: [

            {
              type: "paragraph",
              content: [
                {
                  type: "text",
                  text: "删除这行文字，在新的一行输入英文斜杠/，可以快速开启工具栏（无AI）",
                },
                
              ],
            },
          ],
        },
      ],
    },
    {
      type: "heading",
      attrs: { level: 3 },
      content: [{ type: "text", text: "图像示例" }],
    },
    {
      type: "image",
      attrs: {
        src: "https://anya.yeqin.fun/banner1.png",
        alt: "banner.png",
        title: "banner.png",
        width: null,
        height: null,
      },
    },


    { type: "horizontalRule" },

    {
      type: "heading",
      attrs: { level: 3 },
      content: [{ type: "text", text: "了解更多" }],
    },
    {
      type: "taskList",
      content: [

        {
          type: "taskItem",
          attrs: { checked: false },
          content: [
            {
              type: "paragraph",
              content: [
                { type: "text", text: "基于AI生成，注意保护隐私" },
                
              ],
            },
          ],
        },

        {
          type: "taskItem",
          attrs: { checked: true },
          content: [
            {
              type: "paragraph",
              content: [
                { type: "text", text: "文本存储于当前浏览器内；换电脑或者换浏览器访问都会丢失文本；注意保存" },
                
              ],
            },
          ],
        },

        {
          type: "taskItem",
          attrs: { checked: false },
          content: [
            {
              type: "paragraph",
              content: [
                { type: "text", text: "本页面使用Markdown语法。Markdown简洁、轻巧美观,推荐了解；如果暂时不打算学习，可以在本页面书写内容然后复制粘贴到其他地方。" },
                
              ],
            },
          ],
        },

        {
          type: "taskItem",
          attrs: { checked: false },
          content: [
            {
              type: "paragraph",
              content: [
                { type: "text", text: "一些好玩的 @ " },
                {
                  type: "text",
                  marks: [
                    {
                      type: "link",
                      attrs: {
                        href: "https://gowithai.help",
                        target: "_blank",
                      },
                    },
                  ],
                  text: "GoWithAI.Help",
                },
              ],
            },
          ],
        },
        


      ],
    },
  ],
};
